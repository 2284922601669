* {
	box-sizing: border-box;
}

html {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	-ms-text-size-adjust: 100%;
	-webkit-text-size-adjust: 100%;
	box-sizing: border-box;
	overflow: hidden;
	height: 100%;
	margin: 0;
	color: var(--default-text-color);
	::-webkit-scrollbar:window-inactive {
		display: none;
	}
	::-webkit-scrollbar-track {
		// border-left: 1px solid #e5e5e5;
		border-radius: 10px;
		transition: 0.2s;
	}
	::-webkit-scrollbar-track:hover {
		background-color: #e5e5e56b;
		border-radius: 10px;
	}
	::-webkit-scrollbar-track:window-inactive {
		background-color: transparent;
	}
	::-webkit-scrollbar-thumb {
		// background-color: transparent;
		background-color: rgba(204, 204, 204, 0.78);
		border-radius: 4px;
		transition: 0.4s;
	}
	::-webkit-scrollbar-thumb:hover {
		background-color: rgba(153, 153, 153, 0.856);
	}
	::-webkit-scrollbar-thumb:window-inactive {
		// background-color: yellow;
		background-color: transparent;
	}
}

body {
	padding: 0;
	margin: 0;
	height: 100%;
	overflow: hidden;
	font-weight: 400;
	font-family: "Roboto", sans-serif;
	letter-spacing: 0.4px;
}

:root {
	--primary: #758fff;
}

a {
	background-color: transparent;
	-webkit-text-decoration-skip: objects;
	text-decoration: none;
	color: inherit;
	cursor: pointer;
	font-size: inherit;
}
a:active,
a:hover {
	outline-width: 0;
}

.FR {
	display: flex;
	flex-direction: row;
}
.FC {
	display: flex;
	flex-direction: column;
}
.AC {
	align-items: center;
}
.AL {
	align-items: flex-start;
}
.AR {
	align-items: flex-end;
}
.JC {
	justify-content: center;
}
.JL {
	justify-content: flex-start;
}
.JR {
	justify-content: flex-end;
}
.JSB {
	justify-content: space-between;
}
.JSA {
	justify-content: space-around;
}

.horizontal-container {
	padding: 4rem 10vw;
	// border: 1px solid pink;
	display: flex;
	align-items: center;
	justify-content: center;
}

.heading1 {
	font-size: 48px;
	line-height: 60px;
	letter-spacing: -0.5px;
	font-family: "Roboto Slab", "Roboto", sans-serif;
	font-weight: 100;
}
.heading2 {
	font-size: 36px;
	line-height: 48px;
	letter-spacing: -0.25px;
	font-family: "Roboto Slab", "Roboto", sans-serif;
	font-weight: 100;
}
.heading3 {
	font-size: 28px;
	line-height: 36px;
	letter-spacing: 0.6px;
	font-family: "Roboto Slab", "Roboto", sans-serif;
	font-weight: 100;
}

.body-text1 {
	font-size: 20px;
	line-height: 30px;
	font-weight: 100;
}
.body-text2 {
	font-size: 16px;
	line-height: 26px;
	font-weight: 100;
	letter-spacing: 0.4px;
}

@media (max-width: 680px) {
	.horizontal-container {
		padding: 4rem 10vw;
		// border: 1px solid pink;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.heading1 {
		font-size: 32px;
		line-height: 44px;
		letter-spacing: -0.5px;
		font-family: "Roboto Slab", "Roboto", sans-serif;
		font-weight: 100;
	}
	.heading2 {
		font-size: 28px;
		line-height: 40px;
		letter-spacing: -0.25px;
		font-family: "Roboto Slab", "Roboto", sans-serif;
		font-weight: 100;
	}
	.heading3 {
		font-size: 22px;
		line-height: 30px;
		letter-spacing: 0.6px;
		font-family: "Roboto Slab", "Roboto", sans-serif;
		font-weight: 100;
	}

	.body-text1 {
		font-size: 16px;
		line-height: 24px;
		font-weight: 100;
	}
	.body-text2 {
		font-size: 14px;
		line-height: 1.5em;
		font-weight: 100;
		letter-spacing: 0.4px;
	}
}
